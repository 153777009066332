<template>
  <div class="mobile-subheading">
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "MobileSubheading",
  props: {
    title: {
      type: String,
      default: "Title",
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-subheading {
  height: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  background: #f5f5f5;
  color: #8c8c8c;
  font-weight: 500;
  letter-spacing: 2px;
}
</style>
