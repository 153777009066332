<template>
  <div class="mobile-app">
    <div class="mobile-services-view">
      <mobile-subheading :title="trans('my_bookings_active_bookings')" />
      <v-container>
        <v-row
          v-for="(booking, scheduledBookingIndex) in getScheduledBookings"
          :key="`sb-${scheduledBookingIndex}`"
          no-gutters
        >
          <v-col cols="12" class="mb-4">
            <mobile-booking-card :data="booking" />
          </v-col>
        </v-row>
      </v-container>
      <mobile-subheading :title="trans('my_bookings_previous_bookings')" />
      <v-container>
        <v-row
          v-for="(booking, afterBookingIndex) in getAfterBookings"
          :key="`ab-${afterBookingIndex}`"
          no-gutters
        >
          <v-col cols="12" class="mb-4">
            <mobile-booking-card
              :data="booking"
              display-footer
              display-status
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";
import MobileBookingCard from "../components/MobileBookingCard.vue";
import MobileSubheading from "../components/MobileSubheading.vue";

export default {
  name: "MobileBookingView",
  components: {
    MobileBookingCard,
    MobileSubheading,
  },
  mixins: [mobilePreviewActions],
  computed: {
    ...mapGetters({
      getScheduledBookings: "mobileEditor/getScheduledBookings",
      getAfterBookings: "mobileEditor/getAfterBookings",
    }),
  },
};
</script>

<style lang="scss" scoped>
.mobile-app {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.mobile-services-view {
  height: 100%;
  width: 100%;
}
</style>
