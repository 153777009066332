<template>
  <div
    class="mobile-button"
    :style="{ color: color, background: background, fontSize: size }"
  >
    <v-icon
      v-if="icon.length"
      x-small
      :color="color"
      class="mr-1"
      :style="{ transform: `rotate(${iconRotation}deg)` }"
    >
      {{ icon }}
    </v-icon>
    <span :class="{ strong: strong }">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "MobileButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconRotation: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    background: {
      type: String,
      default: "#000000",
    },
    size: {
      type: String,
      default: "12px",
    },
    strong: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-button {
  padding: 0.6rem;
  border-radius: 0.4rem;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.strong {
  font-weight: 500;
}
</style>
