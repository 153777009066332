<template>
  <div class="mobile-booking-card">
    <div class="mobile-booking-card__background" :style="setBackground()">
      <div class="mobile-booking-card__data mb-2">
        <span>{{ data.date }}</span>
      </div>
      <div class="mobile-booking-card__status my-1">
        <span
          v-if="data.status === 0"
          :class="{
            'green-text-color': data.status === 0 || data.status === 1,
          }"
          ><strong>{{ trans("my_bookings_booking_approved") }}</strong></span
        >
        <span
          v-if="data.status === 1"
          :class="{
            'green-text-color': data.status === 0 || data.status === 1,
          }"
          ><strong>{{ trans("my_bookings_booking_done") }}</strong></span
        >
        <span
          v-if="data.status === 2"
          :class="{ 'red-text-color': data.status === 2 }"
          ><strong>{{ trans("my_bookings_booking_cancelled") }}</strong></span
        >
      </div>
      <div class="mobile-booking-card__title my-1">
        <span
          ><strong>{{ data.title }}</strong></span
        >
      </div>
      <div class="mobile-booking-card__description my-1">
        <span>{{ data.description }}</span>
      </div>
      <div class="mobile-booking-card__cost">
        <span
          ><strong>{{ data.cost | money }}</strong></span
        >
      </div>
      <div class="mobile-booking-card__employee my-1">
        <v-img max-height="30" max-width="30" :src="data.employee.avatar" />
        <span class="mobile-booking-card__employee--full-name">{{
          data.employee.fullName
        }}</span>
      </div>
      <div class="mobile-booking-card__action my-1">
        <mobile-button
          v-if="displayStatus"
          background="#a7aeaf"
          size="8px"
          :text="trans('my_bookings_repeat_booking_button')"
        />
        <mobile-button
          v-else
          background="#ff5e5e"
          :text="trans('my_bookings_cancel_button')"
        />
      </div>
    </div>
    <div v-if="!displayFooter" class="mobile-booking-card__footer">
      <div class="mobile-booking-card__footer--phone">
        <span class="mobile-booking-card__footer--phone---label">{{
          trans("my_bookings_contact_number")
        }}</span>
        <span class="mobile-booking-card__footer--phone---nr">{{
          data.phone
        }}</span>
      </div>
      <div class="mobile-booking-card__footer--action">
        <mobile-button
          background="#78c800"
          icon="$phone"
          :text="trans('my_bookings_call_us_button')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";
import MobileButton from "./MobileButton.vue";

export default {
  name: "MobileBookingCard",
  components: { MobileButton },
  mixins: [mobilePreviewActions],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    displayFooter: {
      type: Boolean,
      default: false,
    },
    displayStatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setBackground() {
      if (!this.displayFooter) {
        return {
          backgroundImage: `url(${require("@/assets/booking-card-footer.svg")})`,
        };
      }
      return {
        backgroundImage: `url(${require("@/assets/booking-card.svg")})`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-booking-card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-booking-card__background {
  width: 100%;
  height: auto;
  display: grid;
  padding: 1rem;
  grid-template-rows: repeat(4, auto);
  grid-template-columns: repeat(4, 1fr);
  font-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile-booking-card__footer {
  background: #dfe0e1;
  height: 84px;
  width: 100%;
  display: flex;
  padding: 1rem;
  font-size: 12px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.mobile-booking-card__footer--phone {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.mobile-booking-card__footer--phone---label {
  color: #5f5f5f;
  font-size: 10px;
}

.mobile-booking-card__footer--phone---nr {
  color: #1d1d1d;
  font-weight: 600;
  font-size: 10px;
}

.mobile-booking-card__footer--action {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mobile-booking-card__footer--action---text {
  padding-left: 0.75rem;
}

.mobile-booking-card__data {
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #5f5f5f;
}

.mobile-booking-card__status {
  grid-row: 1 / 2;
  grid-column: 3 / 5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mobile-booking-card__title {
  grid-row: 2 / 3;
  grid-column: 1 / 4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mobile-booking-card__description {
  grid-row: 3 / 4;
  grid-column: 1 / 4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #5f5f5f;
}

.mobile-booking-card__cost {
  grid-row: 2 / 3;
  grid-column: 4 / 5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mobile-booking-card__employee {
  grid-row: 4 / 5;
  grid-column: 1 / 3;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .v-image {
    border-radius: 50%;
  }
}

.mobile-booking-card__employee--full-name {
  padding-left: 0.5rem;
  word-spacing: 100px;
}

.mobile-booking-card__action {
  grid-row: 4 / 5;
  grid-column: 3 / 5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.green-text-color {
  color: #78c800;
}

.red-text-color {
  color: #ff5e5e;
}
</style>
